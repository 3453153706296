@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader {
  position: relative;
  width: 3rem;
  height: 3rem;
  background-color: white;
  border-radius: 3rem;
  /* transform: rotate(360); */
  animation: rotation 2s infinite linear;
}

.loader-cover {
  position: absolute;
  height: 1.5rem;
  width: 3rem;
  bottom: 0;
  left: 0;
  background-color: #396cb3;
}

.loader-inner {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #396cb3;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
